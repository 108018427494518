@font-face {
  font-family: Candara;

  src: url('./Candara.ttf');
}
@font-face {
  font-family: Segoe UI;
  font-weight: 400;
  src: url('./assets/fonts/segoeui-webfont.woff2') format('woff2'),
    url('./assets/fonts/segoeui-webfont.woff') format('woff');
}
@font-face {
  font-family: Segoe UI;
  font-weight: 700;
  src: url('./assets/fonts/segoeui-semibold-webfont.woff2') format('woff2'),
    url('./assets/fonts/segoeui-semibold-webfont.woff') format('woff');
}

.announcment {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-shrink: 0;
  background: var(--Primary, #102845);
  display: flex;
  align-items: center;
  justify-content: space-around;

  /* margin-bottom: 70px; */
}
.youuu {
  background-color: rgba(0, 128, 0, 0);
}
.holddd {
  width: 50%;
  display: flex;

  justify-content: space-around;
}
.accoumant_words {
  color: #fff;
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
}
.wiko {
  margin-top: -2px !important;
  color: #4ebcd7;
}
.navslinks {
  color: var(--White, #fff);
  /* text-align: center; */
  font-family: 'Segoe UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.navslinks.scrolled {
  color: var(--White, #000000) !important;
  /* text-align: center; */
  font-family: 'Segoe UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contact_us {
  color: var(--White, #fff);
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #6ebad4;
  background: #6ebad4;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.wehhhoo {
  display: flex;
  align-items: center;
}
.wrapper {
  display: flex;

  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.carousel-control-prev {
  display: none !important;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  display: none !important;
}
.carousel-caption {
  position: absolute;
  right: 0% !important;
  bottom: 0rem !important;
  left: 0% !important;
  padding-top: 1.25rem;
  padding-bottom: 0rem !important;
  color: #fff;
  text-align: start !important;
  display: flex !important;
  /* justify-content: center !important; */
  /* align-items: center !important; */
  height: 100% !important;
  width: 100% !important;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  display: none;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.weoeoeo {
  width: 100%;
  height: 700px;
}
.weoeoeo2 {
  width: 100%;
  height: 658px;
}
.push_up {
  margin-top: -70px;
  z-index: -10;
}
.weoeoeo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.weoeoeo2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bground {
  background-color: #10284500;
  z-index: 99999;
  margin-top: 40px;
  transition: background-color 0.3s, margin-top 0.3s;
}
.bground.scrolled {
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  margin-top: 0;
  color: black !important;
}
.zoom-in {
  animation: zoomIn 10s infinite;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.avavav {
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.294) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
}
.bus_name {
  color: #fff;
  width: fit-content;
  border-bottom: 2px solid #fff;
  height: 50px;
  font-family: 'Candara';
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.bus_name2 {
  color: #fff;
  width: fit-content;
  /* border-bottom: 2px solid #fff; */
  height: 50px;
  font-family: 'Candara';
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.candara {
  font-family: Candara;
  font-size: 64px;
  font-weight: 700;
  line-height: 78px;
  letter-spacing: -0.02em;
  text-align: left;
}
.candara2 {
  font-family: Candara;
  font-size: 64px;
  font-weight: 700;
  line-height: 78px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #fff;
}
.empowering {
  color: #fff;
  font-family: 'Candara';
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  width: 75%;
  line-height: normal;
}
.empowering2 {
  color: #fff;
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  width: 39%;
}
.empowering22 {
  color: #fff;
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  width: 55%;
  margin-top: 14px;
}
.empowering3 {
  color: #fff;
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
}
.boggy {
  margin-top: 270px;
}
.boggy2 {
  margin-top: 110px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
.boggy3 {
  margin-top: 110px;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.waii {
  display: flex;
  margin-top: 20px;
}
.serr {
  display: flex;
  height: 48px;
  width: 173px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--White, #fff);
  background: var(--White, #fff);
  color: var(--Primary, #102845);
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.conn {
  display: flex;
  height: 48px;
  width: 173px;
  justify-content: center;
  color: var(--White, #fff);
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  align-items: center;

  margin-left: 20px;
  border-radius: 8px;
  background: var(--Secondary, #4ebcd7);
  border: none;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.missions {
  margin-top: 90px;
}
.amt {
  color: #000;
  /* text-align: center; */
  font-family: 'Candara';
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.camerr {
  color: #000;
  /* text-align: center; */
  font-family: 'Candara';
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.wordss {
  color: #000;
  font-family: 'Segoe UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
}
.service_holders {
  margin-top: 70px;
}
.our_services {
  color: #000;
  font-family: Candara;
  font-size: 64px;
  font-weight: 400;
  line-height: 78.13px;
  text-align: left;
}
.our_talks {
  color: #000;
  width: 80%;
  font-family: 'Segoe UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.holl {
  display: flex;
}
.sub_head {
  color: #000;
  font-family: Candara;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.41px;
  text-align: left;
}
.small_big_talks {
  color: #000;
  font-family: 'Segoe UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 9px;
}
.learn_more {
  color: var(--Dark-grey, #667085);
  font-family: 'Segoe UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}
.conoil {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.conoil img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.location_holder {
  margin-top: 60px;
}
.mage_hold {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mage_hold img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.our_location {
  color: #000;
  font-family: Candara;
  font-size: 36px;
  font-weight: 400;
  line-height: 43.95px;
}
.Douala22 {
  font-family: Candara;
  font-size: 56px;
  font-weight: 400;
  line-height: 68.36px;

  color: #000;
}
.takklo {
  color: #000;
  margin-top: 30px;
  font-family: Segoe UI;
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}
.wiwue {
  display: flex;
  align-items: center;

  height: 100%;
}
.news_holder {
  margin-top: 120px;
}
.news_and_update {
  color: #000;
  text-align: center;
  font-family: 'Candara';
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kard {
  width: 100%;
}
.kard_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.kard_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title_icon {
  display: flex;
  justify-content: space-between;
}
.title_bissh {
  color: var(--Text, #0a0a0a);
  font-family: 'Segoe UI';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}
.lil_talk {
  color: var(--Dark-grey, #667085);
  font-family: 'Segoe UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.hespanian {
  display: flex;
}
.circle {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 10px;
}
.circle img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.first_lin {
  color: var(--Text, #0a0a0a);
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}
.second_lin {
  color: var(--Dark-grey, #667085);
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.testi {
  margin-top: 60px;
  width: 100%;
  background: #0f2743;
  padding: 90px;
}
.Testimonal {
  font-family: Candara;
  font-size: 64px;
  font-weight: 500;
  line-height: 79px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.cennnn {
  display: flex;
  justify-content: center;
  width: 100%;
}
.wikoooe {
  width: 60%;
}
.placess {
  margin: 60px;
}
.greed {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  width: 100%;
}
.flex-up-stae {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.minuss {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.minuss img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.regr {
  margin-left: 20px;
}
.came {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.prooo {
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
  margin-bottom: 3px;
}
.carousel .carousel-status {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
}
.carousel.carousel-slider .control-arrow {
  display: none;
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}
.carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;

  box-shadow: none !important;
  background: #fff;
  width: 30px !important;
  height: 5px !important;
  border-radius: 10px !important;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  background: #4ebcd7;
}
.pleaseee {
  background-color: #102845;
  padding-top: 100px;
  padding-bottom: 80px;
  background-image: url('./image/globe.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  /* background: #102845; */
}
.pleaseee2 {
  background-color: #d9d9d9;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url('./image/globe.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  /* background: #102845; */
}
.loccc {
  font-family: Candara;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  text-align: center;
  color: #ffffff;
}
.loccc2 {
  font-family: Candara;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  text-align: center;
  color: #102845;
}
.captured {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.welpdkjh {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.connn {
  width: 221.09px;
  height: 49.43px;
  border: none;
  border-radius: 44.94px;
  font-family: Candara;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
.connn2 {
  width: 221.09px;
  height: 49.43px;
  border: none;
  border-radius: 44.94px;
  font-family: Candara;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background: #4ebcd7;
}
.exxx {
  width: 221.09px;
  height: 49.43px;
  border: none;
  border-radius: 44.94px;
  background-color: transparent;
  font-family: Candara;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.exxx2 {
  width: 221.09px;
  height: 49.43px;
  border: none;
  border-radius: 44.94px;
  background-color: transparent;
  font-family: Candara;
  font-size: 14px;
  font-weight: 600;
  color: #102845;
}
.Frequently {
  font-family: Candara;
  font-size: 64px;
  font-weight: 400;
  line-height: 44px;
  margin-top: 70px;
  text-align: center;
}
.Everything {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  margin-top: 20px;
}
.vibesss {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.other {
  width: 60%;
  /* background-color: green; */
}
.ispossible {
  font-family: Segoe UI;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a0a0a;
}
.yess {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a0a0a;
}
.ghail {
  background: #102845;
  /* margin-top: 80px; */
  color: white;
}
.joinn {
  font-family: Candara;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.newsletter {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.reveled {
  width: 280px;
  height: 44px;
  padding-left: 17px;
  border-radius: 8px;
  border: 1px;
  gap: 8px;
  outline: none;
}
.send_button {
  width: 109px;
  height: 44px;
  border-radius: 8px;
  padding: 10px, 18px, 10px, 18px;
  background-color: #4ebcd7;
  outline: none;
  border: none;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ffffff;
  margin-left: 40px;
}
.send_button3 {
  width: 115px;
  height: 44px;
  border-radius: 8px;
  padding: 10px, 18px, 10px, 18px;
  background-color: #4ebcd7;
  outline: none;
  border: none;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.send_button2 {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  padding: 10px, 18px, 10px, 18px;
  background-color: #4ebcd7;
  outline: none;
  border: none;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ffffff;
}
.clash {
  font-family: Candara;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.linkkk {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #d9d9d9;
}
.twentty {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.dumbbb {
  display: flex;
  justify-content: flex-end;
}
.niboooo {
  margin-top: 90px;
}
.ursiy {
  font-family: Candara;
  font-size: 64px;
  font-weight: 400;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
}
.weare {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
  margin-top: 9px;
}
.ckassic {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-top: 70px;
}
.reminddd {
  width: 100%;
  display: flex;
  justify-content: center;
}
.billz {
  width: 100%;
  height: 327px;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('./image/frame.png');
}
.pboyqq {
  padding: 50px;
  height: 100%;
}
.yearr {
  font-family: Candara;
  font-size: 64px;
  font-weight: 700;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}
.opening {
  font-family: Candara;
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}
.sus {
  font-family: Candara;
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-top: 14px;
}
.climate {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-top: 14px;
}
.lamooo {
  width: 100%;
  height: 100%;
  border-radius: 19px;
  background: #ffffff;
  box-shadow: 0px 0px 56.599998474121094px 0px #a3aaae17;
  padding: 29px;
  margin: 23px;
}
.wilburr {
  margin-top: 70px;
}
.vivi {
  margin-top: 16px;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.vipro {
  font-family: Candara;
  font-size: 64px;
  font-weight: 400;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.vi {
  font-family: Candara;
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.vipromax {
  width: 80%;

  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
}
.clmate {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18px;
}
.clmate img {
  border-radius: 18px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uju {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f9fafc;
  object-fit: cover;
}
.uju img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f9fafc;
  object-fit: cover;
}
.neverless {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  /* text-align: center; */
  color: #0a0a0a;
  margin-top: 10px;
}
.post {
  font-family: Segoe UI;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #667085;
}
.gracce {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.innerhouse {
  margin-top: 80px;
  margin-bottom: 80px;
}
.bluup {
  background: #102845;
}
.wellop {
  width: 100%;
  height: 484.38px;
  border-radius: 20px;
}
.wellop img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.inttt {
  font-family: Candara;
  font-size: 48px;
  font-weight: 400;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a0a0a;
}

.sofyun {
  margin-top: 30px;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a0a0a;
}
.inttt2 {
  font-family: Candara;
  font-size: 48px;
  font-weight: 400;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.sofyun2 {
  margin-top: 30px;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.willeo {
  padding-top: 60px;
  padding-bottom: 40px;
}
.mounted {
  width: 80%;
  padding-top: 80px;
}
.mounted3 {
  width: 80%;
  padding-top: 20px;
}
.mounted2 {
  width: 100%;
  color: #ffffff !important;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* padding-top: 50px; */
  padding-left: 19px;
}
.paddington {
  padding-top: 90px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 40px;
}
.paddington2 {
  padding-top: 90px;
  padding-left: 270px;
  padding-right: 270px;
  padding-bottom: 80px;
}
.Aboutvv {
  font-family: Candara;
  font-size: 36px;
  font-weight: 400;
  line-height: 43.95px;
  text-align: center;
  color: #000000;
}
.QHSSE {
  font-family: Candara;
  font-size: 64px;
  font-weight: 400;
  line-height: 78.13px;
  text-align: center;
  color: #000000;
}
.alltexts {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #000000;
}
.assembly {
  font-family: Segoe UI;
  font-size: 24px;
  font-weight: 400;

  text-align: left;
}
.wijhjjhjk {
  display: flex;
}
.freq {
  font-family: Candara;
  font-size: 48px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #0a0a0a;
}
.billing {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: #0a0a0a;
}
.quessde {
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #667085;
  width: 100%;
  height: 48px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #000000;
  outline: none;
}
.doubts {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-bottom: 30px;
  color: #0a0a0a;
}
.cus {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #4ebcd7;
  cursor: pointer;
}
.push_all_down {
  margin-top: 50px;
  margin-bottom: 50px;
}
.ouurv {
  font-family: Candara;
  font-size: 64px;
  font-weight: 400;
  line-height: 78.13px;
  text-align: center;
  color: #000000;
}
.Provide {
  margin-top: 30px;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  width: 100%;
}
.okayfelx {
  width: 80%;
}
.yello {
  font-family: Candara;
  font-size: 36px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #0a0a0a;
}
.notice {
  margin-top: 100px;
  display: flex;

  justify-content: center;
  width: 100%;
}
.somehows {
  width: 70%;
}
.paragrah {
  font-family: Segoe UI;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #0a0a0a;
  margin-top: 50px;
}
.join {
  font-family: Candara;
  font-size: 48px;
  font-weight: 700;
  line-height: 58.59px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #0a0a0a;
}
.Explore {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.6px;
  text-align: center;
  margin-top: 18px;
  color: #667085;
}
.jamu {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.benedde {
  width: 60%;
}
.fullot {
  width: 100%;
  /* height: 130px; */
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #eaecf0;
  margin-bottom: 25px;
}
.asedeygo {
  display: flex;
  justify-content: space-between;
}
.Douala {
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.62px;
  text-align: left;
  color: #4ebcd7;
}
.View {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.28px;
  text-align: left;
  color: #4ebcd7;
  cursor: pointer;
}
.wory {
  font-family: Candara;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.97px;
  text-align: left;
  color: #101828;
  margin-top: 9px;
}
.downiee {
  margin-top: 13px;
}
.onsite {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.28px;
  text-align: left;
  color: #667085;
}
.onsite2 {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.28px;
  text-align: left;
  color: #ffffff;
}
.Benefits {
  font-family: Candara;
  font-size: 48px;
  font-weight: 700;
  line-height: 58.59px;
  text-align: center;
  color: #0a0a0a;
}
.uph {
  font-family: Candara;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.41px;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}
.gwawa {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.28px;
  text-align: left;
  color: #000000;
}
.sheiriri {
  background: #102845;
  width: 100%;
  height: 619px;
  display: flex;
  align-items: center;
}
.jibow {
  margin-top: 60px;
  margin-bottom: 60px;
}
.jbno {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000;
}
.ennough {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 30.4px;
  text-align: left;
  color: #000000;
  width: 60%;
}
.fill {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.6px;
  text-align: left;
  color: #000000;
  margin-top: 20px;
}
.inputtt {
  width: 100%;
  height: 54px;
  outline: none;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  opacity: 0px;
  padding-left: 16px;
}
.inputtt2 {
  width: 100%;
  height: 54px;
  background-color: 'red';
  color: 'black' !important;
  border-radius: 6px;
  border: 1px solid #d0d5dd !important;
  opacity: 0px;
  padding-left: 16px;
  padding-right: 10px;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.labelll {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.28px;
  text-align: left;
  color: #000000;
  margin-bottom: 15px;
}
.uploadsdd {
  width: 100%;
  height: 100%;
  top: 32px;
  padding: 12px;
  gap: 0px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #ffffff;
}
.labalaba {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shiba {
  width: 120px !important;
  height: 39px;
  /* padding: 11px; */
  border-radius: 8px;
  background: #000000;
  font-family: Candara;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  color: white;
}
.juuu {
  width: 100%;
}
.juuu img {
  width: 100%;
  object-fit: contain;
}
.textts {
  width: 100% !important;
  height: 192px;
  border-radius: 6px;
  padding: 16px;
  border: 1px solid #e0e0e0;
}
.doodr {
  margin-top: 280px;
}
.pembass {
  font-family: Candara;
  font-size: 36px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #0a0a0a;
}
.eurjr {
  margin-top: 30px;
  width: 100%;

  display: flex;
  justify-content: center;
}
.ejoke {
  width: 60%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.eowirudcu {
  display: flex;
  align-items: center;
}
.nameeeee {
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #0a0a0a;
}
.minnn {
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.62px;
  text-align: left;
  color: #667085;
}

.fkfk {
  height: 60px;
  width: 60px;
  border-radius: 50%;

  margin-right: 14px;
}
.fkfk img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.weiuhg {
  background-color: rgb(172, 172, 172);
  width: 100%;
  height: 600px;
  overflow: hidden;
  border-radius: 9px;
}
.weiuhg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.helaiiu {
  margin-top: 90px;
}
.eurjr2 {
  margin-top: 30px;
  width: 100%;

  display: flex;
  justify-content: center;
}
.toowp {
  width: 70%;
}
.textheaks {
  margin-top: 20px;
  font-family: Candara;
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  text-align: left;
  color: #101828;
}
.nahewe {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 85%,
    rgba(31, 49, 69, 1) 85%
  );
}
.itwo {
  margin-top: 90px;
  margin-bottom: 50px;
}
.gettt {
  font-family: Candara;
  font-size: 36px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #101828;
}
.frerir {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #0a0a0a;
  margin-top: 20px;
  margin-bottom: 40px;
}
.senmess {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  width: 100%;
  height: 48px;
  color: white;
  background-color: #102845;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #102845;
}
.pleasework {
  width: 100% !important;
  height: 48px;
  border-radius: 6px;
  padding: 16px;
  border: 1px solid #e0e0e0 !important;
  padding-left: 12px;
  padding-right: 12px;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: '#667085';
  z-index: 9999999999999999;
}
.wello {
  width: 100%;
  height: 700px;
  /* background-color: red !important; */
  border-radius: 20px;
  z-index: 9999999999999999 !important;
  overflow: hidden;
}
.wello img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.krkrk {
  position: fixed;
  top: 80%;
  right: 40px;
  transform: translateY(-50%);

  z-index: 9999999999999999 !important;
}
.whatsapp_circle {
  width: 70px;
  height: 70px;
  padding: 9px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loose {
  margin-top: 200px;
  margin-bottom: 300px;
}
.favzz {
  font-family: Segoe UI;
  font-size: 30px;
  font-weight: 400;

  text-align: center;
  color: #000000;
}
.spiritt {
  width: 100%;
  height: 340px;
  background-color: rgb(214, 214, 214);
  border-radius: 8px;
  margin-top: 80px;
  overflow: hidden;
}
.spiritt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.send_button2 disabled {
  background-color: grey !important;
  cursor: not-allowed !important;
}
.ql-container.ql-snow {
  border: 1px solid #ccc;
  height: 300px !important;
}
.ahhh {
  width: 60%;
}
@media screen and (max-width: 320px) {
  .subbz {
    margin-top: 12px;
  }
}
@media screen and (max-width: 991px) {
  .ahhh {
    width: 100%;
  }
  .ennough {
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 400;
    line-height: 30.4px;
    text-align: left;
    color: #000000;
    width: 100%;
  }
  .welpdkjh {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .our_talks {
    color: #000;
    width: 100%;
    font-family: 'Segoe UI';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
  .other {
    width: 100%;
    /* background-color: green; */
  }
  .ejoke {
    width: 100%;

    display: flex;
    justify-content: space-between;
  }
  .weedx {
    margin-top: 15px;
  }
  .toowp {
    width: 100%;
  }
  .our_services {
    color: #000;
    font-family: Candara;
    font-size: 34px;
    font-weight: 400;
    line-height: 78.13px;
    text-align: left;
  }
  .benedde {
    width: 100%;
  }
  .somehows {
    width: 100%;
  }
  .okayfelx {
    width: 100%;
  }
  .send_button {
    margin-left: 10px;
  }
  .wijhjjhjk {
    display: flex;
    flex-wrap: wrap;
  }
  .flex-up-stae {
    margin-top: 70px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }
  .paddington {
    padding-top: 90px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
  }
  .paddington2 {
    padding-top: 90px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
  }
  .minuss {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }

  .announcment {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-shrink: 0;
    background: var(--Primary, #102845);
    display: none;
    align-items: center;
    justify-content: space-around;

    /* margin-bottom: 70px; */
  }
  .camerr {
    color: #000;
    /* text-align: center; */
    font-family: 'Candara';
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .empowering {
    color: #fff;
    font-family: 'Candara';
    font-size: 44px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    line-height: normal;
    margin-top: 30px;
  }
  .news_and_update {
    color: #000;
    text-align: center;
    font-family: 'Candara';
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .candara {
    font-family: Candara;
    font-size: 34px;
    font-weight: 700;
    line-height: 48px;
    /* letter-spacing: -0.02em; */
    text-align: left;
  }
  .candara2 {
    font-family: Candara;
    font-size: 34px;
    font-weight: 700;
    line-height: 48px;
    /* letter-spacing: -0.02em; */
    text-align: left;
    color: #fff;
  }
  .waii {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .Frequently {
    font-family: Candara;
    font-size: 24px;
    font-weight: 400;
    line-height: 44px;
    margin-top: 70px;
    text-align: center;
  }
  .empowering2 {
    color: #fff;
    font-family: Segoe UI;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
  }
  .empowering22 {
    color: #fff;
    font-family: Segoe UI;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
  }
  .mounted2 {
    width: 100%;
    color: #ffffff !important;
    /* padding-top: 50px; */
    padding-left: 0px;
  }
  .mounted {
    width: 100%;
    padding-top: 20px;
  }
  .mounted3 {
    width: 100%;
    padding-top: 20px;
  }
  .vipromax {
    width: 100%;
    margin-bottom: 40px !important;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
  }
  .lamooo {
    width: 100%;
    height: 100%;
    border-radius: 19px;
    background: #ffffff;
    box-shadow: 0px 0px 56.599998474121094px 0px #a3aaae17;
    padding: 29px;
    margin: 0px;
  }
  .twooo {
    margin-top: 40px;
  }
  .ckassic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 190%;
    margin-top: 70px;
  }
  .dumbbb {
    display: flex;
    justify-content: flex-start;
  }
  .Testimonal {
    font-family: Candara;
    font-size: 34px;
    font-weight: 500;
    line-height: 79px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding-top: 50px;
  }
  .testi {
    margin-top: 60px;
    width: 100%;
    background: #0f2743;
    padding: 0px;
  }
  .wikoooe {
    width: 100%;
  }
  .bground {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    margin-top: 0px;
  }
  .boggy {
    margin-top: 300px;
  }
  .weoeoeo {
    width: 100%;
    height: 920px;
  }

  .weoeoeo2 {
    width: 100%;
    height: 458px;
  }
  .empowering .empowering2 {
    width: 100%;
  }
  .push_up {
    margin-top: -60px;
    z-index: -10;
  }
  .navslinks,
  .contact_us {
    margin-left: 0px !important;
    margin-top: 15px;
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  .holddd {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .accoumant_words {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}
